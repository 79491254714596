
import { defineComponent } from 'vue';
import { apiEndpoint } from '@/mixin/apiMixin.js';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
//import * as Yup from "yup";
import { Form, Field, ErrorMessage } from 'vee-validate';
import ApiService from '@/core/services/ApiService';
import moment from 'moment';
import { VueCookieNext } from 'vue-cookie-next';
import axios from 'axios';
import { ElNotification } from 'element-plus';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'trainee-extend-attendance',
  components: {
    Field,
    ErrorMessage,
  },
  data() {
    return {
      batch: {
        entity_id: '',
        tranche_id: '',
        institute_id: '',
        course_id: '',
        term_id: '',
        batch_id: '',
        total_terms: '',
      },
      extend_att_date: '' as any,
      extend_limit: 2 as any,
      selectAll: false,
      opendrawer: false,
      secondaryClass: 'btn-secondary ',
      dangerClass: 'btn-danger',
      employee: {},
      batchList: [],
      termList: [],
      details: {},
      tranches_info: [],
      weekplan: {},
      entityTypes: [],
      present: '',
      late: '',
      courseList: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      trainingCalendar: [] as any,
      instituteList: [],
      componentKey: 0,
      tableData: [],
      form: {},
      moment: '' as any,
      loading: false,
      load: false,
      showcalendarDetails: false,
      checkedItems: [] as any,
    };
  },
  async created() {
    this.moment = moment;
    await this.getTranches();
    await this.getEntityInfos();
    this.generateAttenExtendDateLimit();
  },
  methods: {
    handleCheckboxChange(index) {
      const checkedItems = this.trainingCalendar.filter((item) => item.checked);
      this.selectAll = checkedItems.length === this.trainingCalendar.length;
    },
    generateAttenExtendDateLimit() {
      let today = new Date();
      today.setDate(today.getDate() + 3);

      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = today.getFullYear();
      this.extend_att_date = '' as any;
      this.extend_att_date = yyyy + '-' + mm + '-' + dd;
    },
    handleSelectAll() {
      this.trainingCalendar.forEach((item) => {
        item.checked = this.selectAll;
      });
    },
    handleCheckboxClick(data) {
      // Access the row data here and perform any desired operations
      console.log(data);
      // You can also update a property in the component's data or emit an event to pass the data to a parent component
    },
    async handleUpdateClick() {
      // Access the checked items here and perform any desired operations
      // const checkedItems = this.trainingCalendar.filter((item) => item.checked);
      // const uncheckedItems = this.trainingCalendar.filter(
      //   (item) => !item.checked
      // );
      // console.log('Checked Items:', checkedItems);
      // console.log('Unchecked Items:', uncheckedItems);

      let formData = new FormData();

      // only checked Item will pass
      const calendar_data = this.trainingCalendar.filter(
        (item) => item.checked === true
      );

      formData.set('calendar_update_data', JSON.stringify(calendar_data));
      formData.set('extend_att_date', this.extend_att_date);
      formData.set('extend_limit', this.extend_limit);

      await ApiService.post(
        'configurations/super_admin/calendar_extend_update',
        formData
      )
        .then((response) => {
          Swal.fire({
            title: 'Success!',
            text: response.data.data,
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          }).then(() => {
            this.checkedItems = [] as any;
            (this.batch = {
              entity_id: '',
              tranche_id: '',
              institute_id: '',
              course_id: '',
              term_id: '',
              batch_id: '',
              total_terms: '',
            }),
              (this.showcalendarDetails = false);
            this.selectAll = false;
          });
        })

        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTranches() {
      this.loading = true;
      await ApiService.get(this.VUE_APP_TRANCHE_LIST_API)
        .then((response) => {
          this.loading = false;
          this.tranches_info = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },

    async getEntityInfos() {
      this.loading = true;
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get(
        this.VUE_APP_GET_ENTITY_LIST_API + '?entity_id=' + entity_id
      )
        .then((response) => {
          this.loading = false;
          this.entityInfos = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async calenderDetails() {
      if (this.batch.term_id) {
        this.load = true;
        this.showcalendarDetails = true;

        await ApiService.get(
          this.VUE_APP_TRAINING_CALENDAR_DETAILS_API +
            '?batch_info_id=' +
            this.batch.batch_id +
            '&term_no=' +
            this.batch.term_id +
            '&course_id=' +
            this.batch.course_id +
            '&entity_id=' +
            this.batch.entity_id +
            '&tranche_id=' +
            this.batch.tranche_id +
            '&institute_id=' +
            this.batch.institute_id +
            '&extend_flag=' +
            1
        )
          .then((response) => {
            this.load = false;
            this.trainingCalendar = response.data.data;
            this.trainingCalendar.forEach((item) => {
              item.checked = item.attn_extended_date !== null; // Set the 'checked' property based on the condition
            });
            this.componentKey += 1;
          })
          .catch((response) => {
            this.load = false;
            console.log(response);
          });
      } else {
        Swal.fire({
          title: 'Warning!',
          html: 'Please select all required fields.',
          icon: 'warning',
        });
      }
    },

    async getTrainingInstitute() {
      this.loading = true;
      this.batch.institute_id = '';
      this.batch.course_id = '';
      this.batch.batch_id = '';
      this.batch.term_id = '';
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      // this.showcalendarDetails = true;
      this.trainingCalendar = [];
      await ApiService.get(
        this.VUE_APP_INSTITUTE_LIST_API +
          '?entity_id=' +
          this.batch.entity_id +
          '&institute_info_id=' +
          institute_info_id
      )
        .then((response) => {
          this.loading = false;
          this.instituteList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },

    async termInfo() {
      this.batch.term_id = '';
      // this.showcalendarDetails = true;
      this.trainingCalendar = [];
      this.loading = true;
      await ApiService.get(
        this.VUE_APP_GET_BATCH_TERM_API +
          '?batch_info_id=' +
          this.batch.batch_id
      )
        .then((response) => {
          this.loading = false;
          this.termList = response.data.data;
        })
        .catch((response) => {
          this.loading = false;
          console.log(response);
        });
    },
    async batchInfo() {
      this.loading = true;
      this.batch.batch_id = '';
      this.batch.term_id = '';
      // this.showcalendarDetails = true;
      this.trainingCalendar = [];
      await ApiService.get(
        this.VUE_APP_BATCH_LIST_API +
          '?entity_id=' +
          this.batch.entity_id +
          '&course_info_id=' +
          this.batch.course_id +
          '&institute_info_id=' +
          this.batch.institute_id
      )
        .then((response) => {
          this.loading = false;
          console.log(response);
          this.batchList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },

    async courseInfo() {
      this.loading = true;
      this.batch.course_id = '';
      this.batch.batch_id = '';
      this.batch.term_id = '';
      // this.showcalendarDetails = true;
      this.trainingCalendar = [];
      await ApiService.get(
        this.VUE_APP_COURSE_SEARCH_LIST_API +
          '?entity_id=' +
          this.batch.entity_id +
          '&tranche=' +
          this.batch.tranche_id +
          '&institute_info_id=' +
          this.batch.institute_id
      )
        .then((response) => {
          this.loading = false;
          console.log(response);
          this.courseList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
  },
});
